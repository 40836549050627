.container {
  width: 75vw;
  margin: 2em 0 0 5vw;
}

.headerPage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  position: fixed;
  width: 80%;
  z-index: 30;
  background: white;
  top: 100px;
  padding-right: 90px;
  margin-left: 10px
}

.headerNoSearch{
  display: flex;
  justify-content: space-between;
  width: 75%;
  text-align: end;
  position: fixed;
  top: 190px;
  z-index: 6;
  background: white;
  margin-top: 80px;
}


.search {
  width: 50%;
  display: flex;
  gap: 10px;
}

.selectFilter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
}

.selectFilterNoSearch{
  position: absolute;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  /* height: 40px;
  width: 370px; */
  /* background-color: aqua; */
}

.filterTransaction {
  margin-top: 3%;
}

.StepProgression {
  position: relative;
  width: 100%;
  margin: 3em 0;
}

.cardInfo {
  width: 480px;
  height: 320px;
  padding-top: 1%;
  position: absolute;
  position: relative; 
  top: -50%;
  left: 50%;
  transform: translate(-50%);
  z-index: 5;
}

.tableCard {
  margin: 5% 0;
  line-height: 25px;
}
.leftTable {
  margin-left: 5%;
}

.btnCard {
  width: 100%;
  text-align: center;
}

.cardWrap {
  box-shadow: 3px 3px 12px #00000033;
}

.inputStyle {
  width: 170px;
  height: 35px;
}

.checkboxPoint {
  margin-left: 0;
  margin-bottom: 5px;
  width: 150px;
}

.filterIcon {
  margin-left: -12px;
  margin-right: 12px;
}

.btnCancel {
  display: inline-block;
  width: 120px;
  height: 3em;
  padding: 15px;
  text-align: center;
  background-color: var(--lightGreyColor);
  color: gray;
  border-radius: 7px;
}

.selectSearch {
  height: 40px;
  line-height: 40px;
  padding: 10px 0;
}

.table {
  margin-top: 10px;
  font-size: 14px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  height: 380px;
}

.creditBody {
  margin-top: 110px;
}

.simulateBalance {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 30px 0;
}

/* .wrapper{
  position: fixed;
} */

