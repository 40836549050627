.ant-select-multiple:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
    overflow: auto;
  }

  .ant-select-single {
    height: 40px;
  }

  .ant-picker-range {
    height: 40px;
  }
