/* .container {
    width: 91%;
    margin: 2vh auto;
} */
.banner {
    font-size: 14px;
    height: 5em;
    background-color: #f7f7f7;
    border-radius: 10px;
    z-index: 1;
    margin: 10px 0;
    position: relative;
    display: flex;
}

.banner-trapezoid {
    width: 5em;
    padding: 0 6em 0 2em;
    border-radius: 10px 0 0 10px;
    height: inherit;
    border-right: 2.5em solid transparent;
    border-top: 5em solid var(--themeColor);
    color: #fff;
    z-index: 1;
    position: relative;

}

.banner-trapezoid-sub {
    z-index: 3;
    position: relative;
    top: -45px;
}

.banner-sub {
    gap: 3em;
    padding-left: 2em;
    display: flex;
    align-items: center;
}