.icons {
    font-size: 20px;
    margin-right: 8px;
    color: #d3d3d3;
}
.header {
    padding: 18px 10px;
}
.list-header, .header, .desc {
    display: flex;
    justify-content: space-between;
}
.header .title {
    font-size: 20px;
}
.text-area {
    position: relative;
}
.buttons {
    position: absolute;
    bottom: 20px;
    right: 30px;
}
