/* .container {
  width: 78vw;
  display: grid;
  grid-template-columns: 30% 70%;
} */
 

.container {
  width: 75vw;
  margin: 2em 0 2.5em 5vw;
}

.container-routes-content {
  padding: 4em;
}

.profile {
  text-align: center;
}

.profile-content {
  margin: 8px;
}

.sidebar {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.43);
  height: 90vh;
  padding: 3em 1.5em;
}

.sidebar-nav {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
}

.sidebar-navlink {
  padding: 1.3em 1.5em;
  display: grid;
  grid-template-columns: 20% 80%;
  row-gap: 12px;
  align-items: center;
}

.sidebar-navlink:hover {
  background-color: #fff6ea;
  color: #ff5f1f;
}

/* .cardSummary {
  position: relative;
  height: 50px;
  line-height: 40px;
  background-color: var(--bs-gray-400);
} */

div.square {
  width: 100px;
}

.totalSummary {
  margin-left: 50px;
}

.square {
  position: relative;
  width: 80px;
  height: 30px;
  background-color: var(--themeBackgroundColor);
}

.square::before {
  content: "";
  position: absolute;
  top: 0;
  right: -10px;
  width: 25px;
  height: 100%;
  background-color: var(--themeBackgroundColor);
  transform: skewX(-30deg);
}

.square span {
  z-index: 4;
  margin-left: 10px;
  color: var(--creamColor);
}

/* span {
  margin: 5px
} */

.cardSummary div,
.cardSummaryBulk div {
  display: inline-block;
  z-index: 100;
}


.CardStep {
  background-color: #F4F2FF;
  color: var(--bs-gray-400);
  margin-bottom: 2%;
  text-align: center;
  /* height: 80px; */
}

.linkbtn {
  display: inline-block;
}

.cardSummary {
  height: 60px;
  line-height: 30px;
  border-radius: 5px;
  background-color: var(--bs-gray-200);
  margin-top: 90px;
  margin-bottom: -40px;
  position: fixed;
  z-index: 20;
}

.cardSummary div.square {
  width: 200px;
  height: 100%;
  border-radius: 5px;
  top: -30px
}

.cardSummary.square {
  width: 130px;
  height: 100%;
}

.cardSummary .square::before {
  right: -15px;
  width: 35px;
}

.cardSummary .square span {
  margin-left: 45px;
}


.cardSummaryBulk {
  height: 60px;
  line-height: 30px;
  border-radius: 5px;
  background-color: var(--bs-gray-200);
}

.cardSummaryBulk div.square {
  width: 200px;
  height: 100%;
  border-radius: 5px;
  top: -30px
}

.cardSummaryBulk.square {
  width: 130px;
  height: 100%;
}

.cardSummaryBulk .square::before {
  right: -15px;
  width: 35px;
}

.cardSummaryBulk .square span {
  margin-left: 45px;
}

.tableBulk {
  margin-top: 2%;
  position: relative;
  font-size: 12px;
  width: 100%;
  /* overflow-x: auto;
  overflow-y:auto; */
}

.container-lastStep {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.image-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 7px;
}

.image {
  width: 300px;
  height: auto;
  /* max-width: 270px;  */
}

.groupLabel {
  position: absolute;
  margin-left: 8px;
  background-color: var(--color-white);
  z-index: 90;
  font-family: var(--font-karla);
  font-weight: 500;
  /* width: auto; */
  text-align: start;
  background: white;
}

.groupInput2 {
  position: absolute;
  margin-left: 15px;
  background-color: var(--color-white);
  z-index: 90;
  font-family: var(--font-karla);
  margin-top: -35px;
}

.inputForm {
  position: relative;
  margin-top: 11px;
  border-radius: var(--br-3xs);
  height: 40px;
  font-family: var(--font-karla);
  border-radius: 4px;
}