.headerContainer {
  /* border: 1px;
  border-style: solid;
  border-color: black;  */
}

.headerRow {
  justify-content: center;
  align-items: center;
  height: 8vh;
  width: 82vw;
}

/* .headerLeftColContainer {
  padding-left: 6%;
} */

.back {
  width: 100%;
  font-size: 18px;
}

.backTransparent {
  width: 2vw;
  display: block;
}

.headerTitle {
  /* padding-left: 20%; */
  font-weight: 500;
  font-size: 18px;
}

.headerRightColContainer {
  text-align: right;
  /* border: 1px;
  border-style: solid;
  border-color: black;   */
}

.progressRow {
  padding-left: 7%;
  text-align: left;
  width: 40%;
  font-size: 28px;
  /* border: 1px;
    border-style: solid;
    border-color: black;   */
}

.cancel {
  color: var(--greyColor);
  display: inline-block;
}

.progressBTN {
  height: 5vh;
  width: 10vw;
  /* border: 1px;
    border-style: solid;
    border-color: black;   */
}
