.cardInfo {
    width: 50vw;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; 
    top: -80vh;
    left: 50%;
    transform: translate(-50%);
    z-index: 5;
    text-align: center;
    padding: 2% 0
    }

.cardInfo h3 {
  margin-bottom: 5%;
}

.btnCard {
  margin-top: 5%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
 

.formEdit {
    width: 800px;
    overflow-y: auto;
}

.formEdit h4 {
  margin: 5% 0;
}

.formRow {
  display: flex;
  justify-content: center;
align-items: center;
  margin-bottom: 10px;
}

.formColumn {
  display: flex;
  flex-direction: column;
}

.labelColumn {
  margin-right: 10px;
  text-align: end;
}

.inputColumn {
  width: 270px;
  text-align: start;
}


.cardSummary {
    position: relative;
    height: 40px;
    line-height: 40px;
    background-color: var(--bs-gray-400);
}

.cardSummaryBulk {
    height: 50px;
    line-height: 50px;
    border-radius: 5px;
    background-color: var(--bs-gray-200);
}

.cardSummary div, .cardSummaryBulk div {
    display: inline-block;  
}

.cardSummaryBulk div.square {
    width: 200px;
    height: 100%;
    border-radius: 5px;
}

.cardSummaryBulk.square {
    width: 130px;
    height: 100%;
}

.cardSummaryBulk .square::before {
    right: -15px;
    width: 35px;
}

.cardSummaryBulk .square span {
    margin-left: 45px;
}

div.square {
    width: 100px;
  }
  
.totalSummary {
    margin-left: 50px;
}
  
.square {
    position: relative;
    width: 80px;
    height: 30px;
    background-color: var(--themeBackgroundColor);
}
  
.square::before {
    content: "";
    position: absolute;
    top: 0;
    right: -10px;
    width: 25px;
    height: 100%;
    background-color: var(--themeBackgroundColor);
    transform: skewX(-30deg);
}
  
.square span {
    z-index: 4;
    margin-left: 10px;
    color: var(--creamColor);
}
