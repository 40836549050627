.rdbtnGroup {
  width: 100%;
}

.rdbtnRow {
  width: 100%;
}

.rdbtnCol {
  margin: 1%;
  /* border: 1px;
    border-style: solid;
    border-color: black;   */
}

.rdbtnCol1 {
  margin: 1%;
  /* padding: 1%; */
  width: auto;
}

.rdbtn {
  width: 100%;
}

.rbbtnPT {
  display: block;
}

.rbbtnPTinner {
  margin: 0;
  padding: 2px 7px;
}

.icon {
  margin: 15px 10px 0px;
  filter: var(--orangeColorFilter);
  height: 5vh;
}

.rdtext {
  font-size: 14px;
  line-height: 2;
  text-align: center;
}

.rdbtnActive {
  background-color: var(--themeColor);
}

.formItem {
  margin: 0;
  padding-bottom: 10px;
}

.nextBTN {
  text-align: right;
}

.inputText {
  width: auto;
}

.inputTextCS {
  width: 50vw;
}

.selector {
  width: 20vw;
}

.subTitleInfo {
  text-transform: uppercase;
  color: #6767679a;
  margin-bottom: 10px;
}
