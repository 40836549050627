.container {
    width: 75vw;
    margin: 2em 0 0 5vw;
  }
  
  /* .searchData {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px
  }
  
  .search {
    width: 50%; 
    display: flex; 
    gap: 10px
  }
  
  .selectData {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px
  }
    .pagination {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      width: 370px;
    }
  
  .filterData {
    margin-top: 3%
  } */
  
  .cardWrap {
    box-shadow: 3px 3px 12px #00000033;
  }
  
  .inputStyle {
    width: 170px;
    height: 35px;
  }
  
  .checkboxPoint {
    margin-left: 0;
    margin-bottom: 5px;
    width: 150px;
  }
  
  .filterIcon {
    margin-left: -12px;
    margin-right: 12px;
  }
  
  .selectSearch {
    height: 40px;
    line-height: 40px;
    padding: 10px 0;
  }
  
  .table {
    margin-top: 10px;
    font-size: 14px;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    height: 450px;
  }
  
  .StepProgression {
    position: relative;
    width: 100%;
    margin: 3em 0;
    padding: 1.5em 0;
  }
  
  .cardInfo {
    width: 350px;
    height: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -400px;
    left: 50%;
    transform: translate(-50%);
    z-index: 5;
    text-align: center;
    padding: 2% 0;
  }
  
  .textAreaCancel {
    width: 80%;
  }
  
  .textCard {
    margin: 5% 0;
    line-height: 18px;
  }
  
  .btnCard {
    margin-top: 5%;
    width: 100%;
    text-align: center;
  }
  
  .cardSummary {
    position: relative;
    width: 100%;
    height: 40px;
    line-height: 40px;
    background-color: var(--bs-gray-400);
  }
  
  div.square {
    width: 100px;
  }
  
  .totalSummary {
    margin-left: 50px;
  }
  
  .totalSummary span,
  .totalSummaryBulk span {
    margin: 0 10px;
  }
  
  .square {
    position: relative;
    width: 80px;
    height: 100%;
    background-color: var(--themeBackgroundColor);
  }
  
  .square::before {
    content: "";
    position: absolute;
    top: 0;
    right: -10px;
    width: 25px;
    height: 100%;
    background-color: var(--themeBackgroundColor);
    transform: skewX(-30deg);
  }
  
  .square span {
    z-index: 4;
    margin-left: 10px;
    color: var(--creamColor);
  }
  
  .cardSummary div,
  .cardSummaryBulk div {
    display: inline-block;
  }
  
  .CardStep {
    background-color: #f4f2ff;
    color: var(--bs-gray-400);
    margin-bottom: 2%;
    text-align: center;
  }
  
  .linkbtn {
    display: inline-block;
  }
  
  .cardSummaryBulk {
    margin-top: 2%;
    height: 50px;
    line-height: 50px;
    background-color: var(--bs-gray-200);
  }
  
  .cardSummaryBulk div.square {
    width: 200px;
  }
  
  .cardSummaryBulk.square {
    width: 130px;
    height: 100%;
  }
  
  .cardSummaryBulk .square::before {
    right: -15px;
    width: 35px;
  }
  
  .cardSummaryBulk .square span {
    margin-left: 45px;
  }
  
  .tableBulk {
    margin-top: 2%;
    position: relative;
    font-size: 12px;
    /* overflow-x: auto;
    overflow-y:auto; */
  }
  
  .draggerBulk {
    height: 250px;
  }
  
  .formManageCredit {
    width: 60%;
  }
  
  .inputManageCredit {
    margin-bottom: 5%;
  }
  
  .ant-select-single {
    height: 40px;
    margin-right: 16px !important;
}