.img-profile,
.action-icon {
    display: flex;
    gap: 5px;
}
.img-profile {
    flex-direction: column;    
    justify-content: center;
    text-align: center;
}
.action-icon {
    height: 15px;
    justify-content: flex-end;
}
.label-comment {
    font-size: 10px;
}