.container {
  width: 78vw;
  display: grid;
  grid-template-columns: 30% 70%;
}
.container-routes-content {
  padding: 4em;
}
.profile {
  text-align: center;
}
.profile-content {
  margin: 8px;
}
.sidebar {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.43);
  height: 100vh;
  padding: 3em 1.5em;
}
.sidebar-nav {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
}
.sidebar-navlink {
  padding: 1.3em 1.5em;
  display: grid;
  grid-template-columns: 20% 80%;
  row-gap: 12px;
  align-items: center;
}
.sidebar-navlink:hover {
  background-color: #fff6ea;
  color: #ff5f1f;
}

