.sidebar-logo-container {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: transparent;
}

.sidebar-logo {
  width: 80%;
}

.sidebar-menu {
  height: calc(90vh - 100px);
  background-color: transparent;
  overflow-y: scroll;
}

.sidebar-head {
  width: 100%;
  height: 100px;
  padding-left: 15vw;
}

.sidebar-header-container {
  position:fixed; 
  left: 18vw; 
  z-index:10;
  /* margin-left: auto; */
  width: 82vw;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  border-bottom: 1px solid #dedede;
}

.ant-menu-inline .ant-menu-item {
  margin-inline: 0;
  margin-block: 0;
  width: 100%;
  height: 48px;
  border-radius: 0;
}

.ant-menu > .ant-menu-submenu:hover,
.ant-menu > .ant-menu-submenu-selected {
  margin-inline: 0;
  margin-block: 0;
  border-radius: 0;
  width: 100%;
}

.ant-menu-inline .ant-menu-submenu-title {
  margin-inline: 0;
  border-radius: 0;
  width: 100%;
}

.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: inherit;
}

/* .ant-menu-light .ant-menu-submenu-active >.ant-menu-submenu-title {
    color: var(--creamColor);
} */

.ant-menu-inline .ant-menu-item-active {
  border-radius: 0;
}


.ant-menu-light .ant-menu-item-selected,
.ant-menu > .ant-menu-submenu-active 
{
  color: var(--creamColor);
  background-color: var(--themeBackgroundColor);
  border-radius: 0;
}

.ant-menu-light .ant-menu-item-selected svg path,
.ant-menu > .ant-menu-submenu-active svg path 
{
  fill: var(--creamColor);
}



.ant-menu > .ant-menu-submenu-active .ant-menu-title-content
{
  color: var(--creamColor);
}

.ant-layout-header {
  line-height: 1.5;
}

.adm-name {
  font-weight: 600;
  font-size: 15px;
}

.adm-id {
  font-size: 14px;
}

/* .header-items{
    width: 15%;
        padding: 2%;
        position: relative;
        display: inline-block;
        text-align: left;
} */

.header-avatar {
  background-color: var(--lightDGreyColor);
  padding: 20%;
}
/* .content-page {
  background-color: #fff;
} */
