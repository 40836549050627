.container {
    width: 75vw;
    margin: 2em auto;
  }
  
  .btnExport {
    background-color: var(--lightGreyColor);
    color: var(--greyColor);
    margin-left: auto;
    padding: 10px 20px;
    display: flex;
    gap: 10px;
  }
  
  .container-map {
    position: relative;
    z-index: 0;
    margin-top: 70px;
  }
  
  .infoWindow-container {
    padding: 20px;
    width: 35em;
    height:100%;
    font-size: 12px;
  }
  
  .card-container {
    margin-bottom: 2em;
  }
  
  .hub-title {
    font-size: 15px;
  }
  
  .infoWindow-detail-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 15px 0;
  }
  
  .infoWindow-detail-container h6 {
    font-size: 15px;
    color: #979797;
    font-weight: 300;
  }
  
  .card-title {
    color: #979797;
    margin: 20px 0;
  }
  