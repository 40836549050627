.content-container, 
.content, 
.inputs, 
.buttons {
    margin: 20px;
}

.content-container, 
.inputs {
    text-align: center;
}

.buttons {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.yes-button, 
.no-button {
    padding: 10px 15px 30px 15px;
}
 
.yes-button {
    background-color: var(--themeColor);
    color: #fff;
}

.textarea {
    position: relative;
    margin-top: 20px;
}

.attachment {
    position: absolute;
    text-align: left;
    /* border: 1px solid #BFBFBF; */
    border-radius: 3px;
    /* padding: 4px; */
    bottom: 15px;
    left: 10px;
}

.ant-picker .ant-picker-input >input::placeholder {
    color: blue; 
}
