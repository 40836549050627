.inputForm {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--creamColor);
  font-size: 16px;
}

.inputContainer {
  width: 65%;
}

.inputContainer h4 {
  font-size: 24px;
}

.inputWrap {
  display: flex;
  flex-direction: column;
}

.inputWrap input,
.inputWrap label {
  width: 100%;
}

.inputWrap input, 
.inputPassword,
.inputField {
  padding: 2% 3%;
}

.inputLabel,
.inputButton {
  font-size: 16px;
}

.inputButton {
  width: 100%;
  height: 100%;
  padding: 2% 0;
  margin: 2% 0;
}

.input {
  margin: 11% 0;
}

.forgotPassword {
  font-size: 14px;
}

.inputCheckbox {
  margin-top: 3%;
}

.inputCheckbox input {
  width: 3%;
  margin-right: 3%;
}

.labelPass {
  margin-top: 3%;
}

.cursorPointer {
  cursor: pointer;
}
