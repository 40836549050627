.labelInput {
    position: absolute;
    font-size: 14px !important;
    left: 24px !important;
    transform: translateY(-50%) !important;
    background-color: white;
    padding: 0 0.3rem !important;
    margin: 0 0.5rem !important;
    pointer-events: none;
    z-index: 100;
}

.inputForm {
    font-size: 16px;
    outline: none;
    border: 1px solid #C9C9C9;
    border-radius: 5px;
    padding: 0.5rem 0.7rem;
    color: black;
    transition: 0.1s ease-out;
    position: relative;
    /* z-index: 1111; */
}

.materialTextField {
    position: relative;
}

.optionDrawer{
    text-align: left !important;
}
