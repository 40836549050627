.app-frame {
  display: flex;
  flex-direction: row;
  overflow: auto;
  min-height: 100vh;
  background-color: white;
  margin-left: 15vw;
}

.app-content {
  display: flex;
  flex-direction: column;
  margin-top: 90px;
  flex-grow: 1;
  overflow: auto;
}
