.signup-bg {
  background-image: var(--page_ssoAccess_bg);
  background-size: cover;
  height: var(--page_ssoAccess_bg_height);
  width: var(--page_ssoAccess_bg_width);
}

.container {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  background-color: #fff6ea;
  height: 90vh;
}

.contextLayout {
  width: 100vw;
  /* border: 1px;
  border-style: solid;
  border-color: black; */
}