/* .container {
        width: 75vw;
        margin: 2em 0 0 5vw;
} */

.tagline {
    font-size: 12px;
    line-height: 28px;
}

.contentContainer {
    display: flex;
}

.orderContainer {
    width: inherit;
}

.orderCard {
    width: 100%;
    padding: 1.5em 2.5em;
}

.orderTitle {
    font-size: 18px;
}

.orderCardInnerRow {
    width: inherit;
    margin-bottom: 20px;
}

.orderTable {
    width: 100%;
}

.orderData {
    font-size: 12px;
}

.SRCard {
    min-height: 40vh;
    width: 100%;
    padding: 3em;
}

.SRCardTitle {
    font-size: 14px;
}

.h2hContainer {
    text-align: right;
    font-size: 14px;
}

.hubContainer {
    padding-left: 2vw;
}

.hubCard {
    background-color: var(--lightGreyColor);
    min-height: 30vh;
    width: 100%;
}

.hubTitle {
    margin-bottom: 15px;
}

div .sender-receiver-content {
    font-size: 12px;
    margin-bottom: 12px;
    /* overflow-x: auto; */
}

.sender-receiver-content-1 {
    font-size: 12px;
}

.copyBTN {
    font-size: 10px;
    color: #4C3CFF;
    border: 1px;
    border-style: solid;
    border-color: #4C3CFF; 
}

.copyBTN:active {
    color: white;
    background-color: #4C3CFF;
}