.title {
  font-size: 14px;
  font-weight: bolder;
}

.box {
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.3);
  padding: 2.5em;
  border-radius: 10px;
  width: 100%;
}

.grid {
  display: grid;
  grid-template-columns: 30% 70%;
  position: relative;
  width: auto;
  /* border: 1px;
    border-style: solid;
    border-color: black; */
}

.arr {
  position: absolute;
  right: 0;
  top: 0;
}

.childNotShown {
  display: none;
}

.childShown {
  display: block;
  margin-right: 4%;
  /* border: 1px;
    border-style: solid;
    border-color: black; */
}
