.cardInfo {
  width: 50vw;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -80vh;
  left: 50%;
  transform: translate(-50%);
  z-index: 5;
  text-align: center;
  padding: 2% 0
}

.cardInfo h3 {
  margin-bottom: 5%;
}

.btnCard {
  margin-top: 5%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}


.formEdit {
  /* width: 880px; */
  overflow-y: auto;
}

.formEdit h4 {
  margin: 5% 0;
}

.formRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.formColumn {
  display: flex;
  flex-direction: column;
}

.labelColumn {
  margin-right: 10px;
  text-align: end;
}

.inputColumn {
  width: 270px;
  text-align: start;
}

.pictureCard {
  position: relative;
  display: inline-block;
  margin-right: 8px;
}

.image {
  width: 64px;
  height: 64px;
  object-fit: cover;
}