.reset-button {
  background-color: transparent;
  padding: 0;
  margin: 0;
  font-size: 15px;
}
.form-action {
  display: flex;
  border-bottom: 0.5px solid #c2c2c2;
  padding: 25px 0;
  margin-bottom: 15px;
}
.right {
  margin-left: auto;
  display: flex;
  gap: 15px;
}
