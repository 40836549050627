.title,
.header-timeline {
    font-size: 20px;
    font-weight: 500;
}

.subtitle {
    font-size: 16px;
    margin: 10px 0 30px 0;
}

.btn-submit {
    padding: 10px 0 40px 0;
    font-size: 18px;
    margin: 15px 0;
}

.header-timeline,
.expired-title {
    display: flex;
    gap: 15px;
}

.highlight-timeline {
    display: grid;
    grid-template-columns: 25% 55%;
}

.container-track {
    display: flex;
    flex-direction: column;
    gap: 3em;
    padding: 3em 5em;
}

.timeline-title {
    width: 550px;
    display: flex;
    justify-content: space-between;
}

.timeline-title h1 {
    font-size: 16px;
    width: 330px;
}

.timeline-subtitle {
    font-size: 14px;
    margin: 10px 0 25px 0;
}

.image-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 7px;
}

.image {
    width: 300px;
    height: auto;
    /* max-width: 270px;  */
}