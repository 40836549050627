  .ant-steps .ant-steps-item-finish .ant-steps-item-container .ant-steps-item-tail::after,
  .ant-steps-item-finish .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon .ant-steps-icon-dot,
  .ant-steps-item-active .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon .ant-steps-icon-dot {
    /* background-color: var(--bs-success); */
    background: #247418;
  }

  .custome-step .ant-steps-item-finish .ant-steps-item-container .ant-steps-item-tail::after,
  .custome-step .ant-steps-item-finish .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon .ant-steps-icon-dot,
  .custome-step .ant-steps-item-active .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon .ant-steps-icon-dot {
    background-color: red;
  }

  .ant-steps.ant-steps-dot .ant-steps-item-content,
  .ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-content {
    width: 100px;
  }

  .ant-steps.ant-steps-dot .ant-steps-item-icon,
  .ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
    margin-inline-start: 48px;
  }

  .ant-steps.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
  .ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
    margin-inline-start: 48px;
  }

  .ant-steps.ant-steps-dot .ant-steps-item-tail,
  .ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
    margin-inline: 50px 0;
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .progressContent {
    font-size: 11px;
  }

  .progressDesc {
    position: absolute;
    top: -52px;
    left: 7px;
    font-size: 12px;
  }

  .icons {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    gap: 4px;
    align-items: baseline;
  }

  /* 
  .ant-steps.ant-steps-dot .ant-steps-item-content, .ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-content {
    width: 120px;
  }

  .ant-steps.ant-steps-dot .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot, .ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot {
    left: 5px;
  }

  .ant-steps.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot, .ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
    left: 10px;
  }

  .ant-steps.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon, .ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
    margin-inline-start: 48px;
  }

  
  .ant-steps.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot, .ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot,
  .ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title,
  .ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title,
  .ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    margin-top: 25px;
  } 

  .ant-steps-item-title{
  height: 90px;
}

.progressDesc{
  position: absolute;
  top: -40px;
  left: 12px;
  font-size: 12px;
  margin: 5px 0;
}
 */